<template>
  <section id="appointment-logs">
    <div v-if="isLoading" class="d-flex justify-content-center mt-5 mb-5">
      <b-spinner
        variant="primary" 
        label="Spinning"
      ></b-spinner>
    </div>
    <div v-else-if="logs?.length">
      <b-table-simple striped>
        <thead>
          <tr>
            <th
              v-for="head in headers"
              :key="head"
            >
              {{  head }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(log, index) in logs"
            :key="index"
          >
            <td>{{ log.date_time.format('DD/MM/YYYY') }}</td>
            <td>{{ log.date_time.format('HH[:]mm') }}</td>
            <td :id="`user-${index}`">
              {{ log.user?.name }}
            </td>
            <b-popover 
              v-if="log.user?.name?.length > 20"
              :target="`user-${index}`" 
              triggers="hover" 
              placement="bottom"
            >
              {{ log.user?.name }}
            </b-popover>
            <td>{{ parseCategory[log.category] }}</td>
            <td :id="`description-${index}`">
              <span v-html="log.description"></span>
            </td>
          </tr>
        </tbody>
      </b-table-simple>
      <div class="pagination-position">
        <b-pagination
          v-model="page"
          :total-rows="total"
          :per-page="limit"
          first-number
          last-number
          size="sm"
        />
      </div>
    </div>
    <div v-else class="text-align-center mt-5 mb-5">
      Nenhum histórico disponível.
    </div>
  </section>
</template>

<script>
import {getMomentWithTimezoneOffset} from "@/utils/dateHelper"

export default {
  props: {
    appointmentId: String,
  },

  data () {
    return {
      headers: [
        'Data',
        'Horário',
        'Responsável',
        'Categoria',
        'Descrição',
      ],
      parseCategory: {
        'create': 'Criação',
        'update': 'Atualização',
        'delete': 'Exclusão',
      },
      logs: [],
      page: 1,
      total: 0,
      limit: 20,
      isLoading: true,
    }
  },

  async created(){
    await this.getAppointmentLogs()
  },

  watch: {
    async page(){
      await this.getAppointmentLogs()
    }
  },

  methods: {
    async getAppointmentLogs () {
      try {
        this.isLoading = true
        const res = await this.api.getLogs('appointment', this.appointmentId, this.page)
        this.logs = this.formatData(res.data.data)
        this.total = res.data.total
        this.limit = res.data.per_page
      } catch (err) {
        this.$toasted.error('Não foi possível buscar o histórico deste agendamento, contate o suporte se o erro persistir.')
      } finally {
        this.isLoading = false
      }
    },

    formatData(data) {
      return data.map(log => {
        log.date_time = getMomentWithTimezoneOffset(log.date_time)
        return log
      })
    }
  }
}
</script>
<style lang="scss">
#appointment-logs {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;

  table {
    width: 100%;
    margin: 0 0 16px;
    thead {
      background: var(--neutral-100);
      border-radius: 8px;
  
      tr {
        padding: 8px 8px 0 0;
        th {
          border: none;
          &:first-child {
            border-radius: 8px 0 0 0;
          }
          &:last-child {
            border-radius: 0 8px 0 0;
          }
        }
      }
      th {
        padding: 1rem;
        color: var(--dark-blue);
        font-weight: 700;
        font-size: 14px;
        border: none;
      }
    }

    tbody {
      tr {
        td {
          padding: 1.5rem 0 1.5rem 10px;
          color: var(--type-active);
          font-weight: 500;
          max-width: 40ch;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: middle;
        }

        &:nth-child(odd) {
          background-color: var(--neutral-000) !important;
        }
        &:nth-child(even) {
          background-color: var(--neutral-70);
        }
        &:last-child {
          td {
            &:first-child {
              border-radius: 0 0 0 8px;
            }
            &:last-child {
              border-radius: 0 0 8px 0;
            }
          }
        }
      }
    }
  }
  .pagination-position {
    padding: 0;
  }
}
</style>
